<template>
  <main class="content-500" id="profil">
    <section class="t-center pad-16">
      <h1>{{ user.firstname }}</h1>
      <h2>Willkommen auf deinem Profil</h2>
    </section>
    <section id="profilheader">
      <img :src="config.projectURL + 'assets/images/users/' + user.avatar" class="image width-150 circular">
      <div class="profilheaderInfos">
        <router-link to="settings" class="bgcolor-second color-white button settingsbutton">
          <img src="assets/images/icons/settings-white.svg" class="width-24">
        </router-link>
        <!--
        <button class="bgcolor-second color-white button sharebutton">
          <img src="assets/images/icons/share-white.svg" class="width-20">
        </button>
        -->
        <button @click="auth.logout()" class="button signOutButton bgcolor-orange color-white br-8">
          Logout
        </button>
      </div>
    </section>
    <section class="pad-16" id="friendsRequests" v-if="friendsRequests > 0" @click="friendsRequestModal = true;">
      <div class="bgcolor-second color-orange br-10 t-center pad-16">
        <p>{{ friendsRequests }} offene Anfragen</p>
      </div>
    </section>
    <section id="friendscontainer" class="pad-16">
      <div class="pad-16 br-10">
        <div class="grid-12 header">
          <div class="col-sp-6 row-sp-12">
            <h4 class="color-white">
              Freunde
            </h4>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <button class="button bgcolor-orange color-white" @click="friendsModal = true;">
              Verwalten
            </button>
          </div>
        </div>
        <div class="grid-12">
          <friendItems v-for="fitems in friendItems" :friend-items="fitems" :key="fitems.id" />
        </div>
        <p v-if="friendItems == ''" class="pad-16 color-white t-center">
          Du hast noch keine Freunde hinzugefügt.
        </p>
      </div>
    </section>
    <section id="locationscontainer" class="pad-16">
      <div class="pad-16 br-10">
        <div class="grid-12 header">
          <div class="col-sp-6 row-sp-12">
            <h4 class="color-white">
              Deine <span class="color-orange f-bold">Standorte</span>
            </h4>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <button class="button bgcolor-orange color-white" @click="myCitiesModal = true;">
              Verwalten
            </button>
          </div>
        </div>
        <div class="grid-12">
          <cityItems v-for="citems in cityItems" :city-items="citems" :key="citems.id" />
        </div>
        <p v-if="cityItems == ''" class="pad-16 color-white t-center">
          Du hast noch keine Standorte hinzugefügt.
        </p>
      </div>
    </section>
    <div class="pad-16 t-right" />
  </main>
  <transition name="modal" id="myCitiesModal">
    <div class="vueModal" v-if="myCitiesModal">
      <div class="vueModalOuter" @click="myCitiesModal = false;" />
      <div class="vueModalInner bottom bgcolor-second">
        <div class="t-center pad-8">
          <h2>Meine Standorte verwalten</h2>
          <form>
            <SimpleTypeahead id="city_search" placeholder="Standort hinzufügen..." :items="cities" :min-input-length="1" :item-projection="item => {return item.name}" @selectItem="selectCity" @onInput="loadCitys" />
          </form>
          <p style="font-size: 0.8em" v-if="addCityMessage != null" class="color-white">
            {{ addCityMessage }}
          </p>
          <p style="font-size: 0.8em" v-if="errorMessageAlreadyExist == true" class="color-orange">
            Du hast diese Stadt bereits hinzugefügt.
          </p>
          <div class="pad-4" />
          <div id="myCitiesSelection">
            <citySelectionItems v-for="citems in citySelectionItems" :city-selection-items="citems" :key="citems.id" @update="getMyCities()" />
            <div class="pad-16" />
          </div>
          <button @click="myCitiesModal = false;" class="button bgcolor-orange color-white fluid">
            Okay
          </button>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="addFriendModal">
    <div class="vueModal" v-if="addFriendModal">
      <div class="vueModalOuter" @click="addFriendModal = false;" />
      <div class="vueModalInner bottom bgcolor-second">
        <div class="t-center pad-8">
          <h2>Freund hinzufügen</h2>
          <form @submit.prevent="addFriend()">
            <div class="grid-12">
              <div class="col-sp-9 row-sp-12">
                <div class="field">
                  <input type="email" placeholder="E-Mail-Adresse des Nutzers" v-model="friendEmail" required>
                </div>
              </div>
              <div class="col-sp-3 row-sp-12 t-right">
                <button type="submit" class="button tiny bgcolor-orange color-white" style="padding: 4px 8px;">
                  <img src="assets/images/icons/addfriend-white.svg" class="width-28">
                </button>
              </div>
            </div>
          </form>
          <p style="font-size: 0.8em" v-if="errorMessageAlreadyFriend == true" class="color-orange">
            Du bist bereits mit dem Nutzer befreundet oder hast bereits eine Anfrage gesendet.
          </p>
          <p style="font-size: 0.8em" v-if="errorMessageFriendNotFound == true" class="color-orange">
            Es konnte kein Nutzer mit dieser E-Mail-Adresse gefunden werden.
          </p>
          <p style="font-size: 0.8em" v-if="successMessageFriend == true" class="color-white">
            Freundschaftsanfrage erfolgreich versendet.
          </p>
          <div class="pad-4" />
          <button @click="addFriendModal = false;" class="button bgcolor-black color-white fluid">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="friendsModal">
    <div class="vueModal" v-if="friendsModal">
      <div class="vueModalOuter" @click="friendsModal = false;" />
      <div class="vueModalInner bottom bgcolor-second">
        <div class="t-center pad-8">
          <h2>Deine Freunde</h2>
          <div id="friendList">
            <friendlistItems v-for="flitems in friendlistItems" :friendlist-items="flitems" :key="flitems.id" @update="getFriendships()" />
          </div>
          <p v-if="friendlistItems == ''" class="pad-16 color-white t-center">
            Du hast noch keine Freundschaften.
          </p>
          <button @click="friendsModal = false; addFriendModal = true;" class="button fluid bgcolor-orange color-white">
            Freund hinzufügen
          </button>
          <div class="pad-4" />
          <button @click="friendsModal = false;" class="button bgcolor-black color-white fluid">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="friendsRequestModal">
    <div class="vueModal" v-if="friendsRequestModal">
      <div class="vueModalOuter" @click="friendsRequestModal = false;" />
      <div class="vueModalInner bottom bgcolor-second">
        <div class="t-center pad-8">
          <h2>Offene Freundschaftsanfragen</h2>
          <div id="friendRequestList">
            <friendsRequestItems v-for="fritems in friendsRequestItems" :friends-request-items="fritems" :key="fritems.id" @update="getFriendsrequests();getFriendships();" />
          </div>
          <p v-if="friendsRequestItems == ''" class="pad-16 color-white t-center">
            Du hast keine offenen Freundschaftsanfragen.
          </p>
          <div class="pad-4" />
          <button @click="friendsRequestModal = false;" class="button bgcolor-black color-white fluid">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import friendItems from "@/components/profil/friends";
import friendsRequestItems from "@/components/profil/friendsrequests";
import friendlistItems from "@/components/profil/friendlist";
import cityItems from "@/components/profil/myCities";
import citySelectionItems from "@/components/profil/myCitiesSelection";

import SimpleTypeahead from 'vue3-simple-typeahead'

export default {
  name: "Profil",
  title: "Profil",
  components: {
    'friendItems': friendItems,
    'friendlistItems': friendlistItems,
    'friendsRequestItems': friendsRequestItems,
    'cityItems': cityItems,
    'citySelectionItems': citySelectionItems,
    SimpleTypeahead
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      city: null,
      cities: [],
      searchString: '',
      friendsRequests: 0,
      cityItems: '',
      friendItems: '',
      friendlistItems: '',
      friendsRequestItems: '',
      citySelectionItems: '',
      myCitiesModal: false,
      friendsModal: false,
      friendsRequestModal: false,
      errorMessageAlreadyExist: false,
      addCityMessage: null,
      addFriendModal: false,
      errorMessageAlreadyFriend: false,
      errorMessageFriendNotFound: false,
      successMessageFriend: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getMyCities()
    this.getFriendships()
    this.getFriendsrequests()
  },
  methods: {
    addFriend(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('friendEmail', this.friendEmail)
      this.axios.post('profil/addFriend', fD)
          .then((response) => {
            if(response.status === 250){
              this.successMessageFriend = true;
              this.errorMessageAlreadyFriend = false;
              this.errorMessageFriendNotFound = false;
              this.friendEmail = '';
              this.getFriendships();
            }
            if(response.status === 251){
              this.errorMessageAlreadyFriend = true;
              this.successMessageFriend = false;
              this.errorMessageFriendNotFound = false;
            }
            if(response.status === 252){
              this.errorMessageFriendNotFound = true;
              this.successMessageFriend = false;
              this.errorMessageAlreadyFriend = false;
            }
          })
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.cityItems = response.data
              _this.citySelectionItems = response.data
            }
          })
    },
    getFriendships(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getFriendships', fD)
          .then((response) => {
            if(response.status === 250){
              _this.friendItems = response.data
              _this.friendlistItems = response.data
            }
          })
    },
    getFriendsrequests(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getFriendsrequests', fD)
          .then((response) => {
            if(response.status === 250){
              _this.friendsRequests = response.data.length
              _this.friendsRequestItems = response.data
            }
          })
    },
    loadCitys(event){
      const _this = this
      this.searchString = event.input
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('search', event.input)
      this.axios.post('profil/cities', fD)
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectCity(item){
      this.city = item
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('cityId', item.id)
      this.axios.post('profil/addMyCity', fD)
          .then((response) => {
            if(response.status === 250){
              this.getMyCities()
              document.getElementById('city_search').value = '';
              this.errorMessageAlreadyExist = false;
              this.addCityMessage = 'Du hast '+item.name+' zu deiner Liste hinzugefügt.'
            }
            if(response.status === 252){
              this.errorMessageAlreadyExist = true;
              this.addCityMessage = null;
            }

          })
    }
  }
}
</script>

<style>

main#profil{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#profil h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#profil h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: -32px;
}
#profilheader{
  text-align: center;
  padding-top: 40px;
}
#profilheader img{
  border: 2px solid #ffffff;
  position: relative;
  z-index: 1;
}
#profilheader .profilheaderInfos{
  background-color: var(--second-bg-color);
  width: calc(100% - 32px);
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 100px;
  position: relative;
  z-index: 0;
  margin-top: -80px;
  padding-bottom: 16px;
  margin-bottom: 32px;
}
#profilheader .profilheaderInfos button.sharebutton{
  position: absolute;
  right: 8px;
  top: 8px;
}
#profilheader .profilheaderInfos a.settingsbutton{
  position: absolute;
  left: 8px;
  top: 8px;
}
#profilheader .profilheaderInfos button.sharebutton img,
#profilheader .profilheaderInfos a.settingsbutton img{
  border: none;
}
#profilheader .profilheaderInfos button.signOutButton{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 48px;
}
#friendsRequests{
  cursor: pointer;
}
#friendsRequests p{
  font-weight: 200;
  font-size: 1.2em;
}
#friendscontainer .header{
  padding-bottom: 8px;
  margin-bottom: 16px;
}
#friendscontainer .header h4{
  font-size: 1.4em;
  font-weight: 200;
  padding-top: 6px;
  font-family: 'Helvetica';
}
#friendscontainer .header button{
  padding: 10px;
}
#locationscontainer .header{
  padding-bottom: 8px;
  margin-bottom: 16px;
}
#locationscontainer .header h4{
  font-size: 1.4em;
  font-weight: 200;
  padding-top: 6px;
  font-family: 'Helvetica';
}
#locationscontainer .header button{
  padding: 10px;
}
#friendsRequestModal,
#friendsModal,
#addFriendModal,
#myCitiesModal{
  position: fixed;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
}
#friendsRequestModal h2,
#friendsModal h2,
#addFriendModal h2,
#myCitiesModal h2{
  font-size: 1.4em;
}
#friendList,
#friendRequestList{
  margin: 8px 0;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
#addFriendModal input{
  width: 100%;
  background-color: #000000;
  padding: 12px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
}
#addFriendModal form{
padding: 8px 0 32px 0;
}

#myCitiesModal #city_search{
  width: calc(100% - 48px);
  border-radius: 8px;
  padding: 12px;
  border: none;
  background-color: #000000;
  color: #ffffff;
  padding-left: 36px;
  background-image: url('/public/assets/images/icons/search-grey.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
#myCitiesSelection{
  max-height: 200px;
  overflow-y: scroll;
}
</style>
import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import title from '@/mixins/title'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import deLocale from "@/locales/de.json"
import enLocale from "@/locales/en.json"
import {configStore} from "@/store/configStore";
import modal from "@/components/basic/modal";

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = Vue.markRaw(router)
});

const i18n = new createI18n({
  locale: 'en', // set locale
  messages: {
    en: enLocale,
    de: deLocale
  }, // set locale messages
})

const vueProto = Vue.createApp(App)
    .use(pinia)
    .use(router)
    .use(VueAxios, axios)
    .use(i18n)
    .mixin(title)

const config = configStore()
vueProto.axios.defaults.baseURL = config.projectURL
vueProto.config.warnHandler = () => null;
vueProto.component('VueModal', modal)

router.isReady().then(() => vueProto.mount('#App'))
<template>
  <main id="eventFinder">
    <section id="step0" class="content-500" v-if="step == 0">
      <h1>Wo gehts ab?</h1>
      <h2>Dein Eventfinder</h2>
      <div class="description">
        <h4 style="visibility: hidden;">
          {{ step }} von 3
        </h4>
        <h3>Was ist der Eventfinder?</h3>
        <p>
          Mit dem Eventfinder findest du klick für klick die Party,
          nach der du bereits den ganzen Abend suchst.
        </p>
      </div>
      <div class="eventFinderForm">
        <div class="field">
          <label for="eventDate">Eventdatum</label>
          <input type="date" id="eventDate" v-model="eventDate">
        </div>
        <div class="t-center">
          <button v-if="eventDate" @click="step = 1;" class="button bgcolor-orange submit color-white">
            Los geht's!
          </button>
        </div>
      </div>
    </section>
    <section id="step1" class="content-500" v-else-if="step == 1">
      <h1>Wo gehts ab?</h1>
      <h2>Dein Eventfinder</h2>
      <div class="description">
        <h4>{{ step }} von 3</h4>
        <h3>Art des Events</h3>
        <p>
          In welche Richtung darf es gehen?
          Clubnacht, Underground Konzert oder Kunstveranstaltung
        </p>
      </div>
      <div class="eventFinderForm">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="Club" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <span>🪩</span>
              <h5>Club</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="Rave" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <span>🎧</span>
              <h5>Rave</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="Konzert" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <span>🎤</span>
              <h5>Konzert</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="Straßenfest" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <span>🎉</span>
              <h5>Straßenfest</h5>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="clickCover" data-value="Festival" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <span>⛺</span>
              <h5>Festival</h5>
            </div>
          </div>
        </div>
        <div class="t-center">
          <button @click="setLabels(); step = 2;" class="button bgcolor-orange submit color-white">
            Weiter
          </button>
        </div>
      </div>
    </section>
    <section id="step2" class="content-500" v-else-if="step == 2">
      <h1>Wo gehts ab?</h1>
      <h2>Dein Eventfinder</h2>
      <div class="description">
        <h4>{{ step }} von 3</h4>
        <h3>Musikrichtung</h3>
        <p>
          Zu welcher Musik gehst du
          richtig ab?
        </p>
      </div>
      <div class="eventFinderForm">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="15" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Schlager</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="14" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Pop</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="16" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Rock</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="17" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Hip-Hop</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="18" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Electro</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="19" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Dance</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="20" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Reggae</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="12" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>80er</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="10" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>90er</h5>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <div class="clickCover" data-value="11" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>2000er</h5>
            </div>
          </div>
        </div>
        <div class="t-center">
          <button @click="setCategories(); step = 3;" class="button bgcolor-orange submit color-white">
            Weiter
          </button>
        </div>
      </div>
    </section>
    <section id="step3" class="content-500" v-else-if="step == 3">
      <h1>Wo gehts ab?</h1>
      <h2>Dein Eventfinder</h2>
      <div class="description">
        <h4>{{ step }} von 3</h4>
        <h3>Details zum Event</h3>
        <p>
          Die wirklich wichtigen Fragen im Leben
        </p>
      </div>
      <div class="eventFinderForm">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="clickCover" data-value="25" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Evently Pay</h5>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="clickCover" data-value="24" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Kartenzahlung</h5>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="clickCover" data-value="23" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>Barrierefrei</h5>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="clickCover" data-value="2" @click="e => e.target.classList.toggle('active')">
              <div class="activeCheck">
                <img src="assets/images/icons/check-icon.svg" class="width-24">
              </div>
            </div>
            <div class="bgcolor-second br-10 color-white selectionItem">
              <h5>LGBTIQA+ Freundlich</h5>
            </div>
          </div>
        </div>
        <div class="t-center">
          <button @click="setCategories(); loadResults(); step = 4;" class="button bgcolor-orange submit color-white">
            Weiter
          </button>
        </div>
      </div>
    </section>
    <section id="step4" class="content-500" v-else-if="step == 4">
      <h1>Das geht ab!</h1>
      <h2>Wir wünschen dir viel Spaß!</h2>
    </section>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "EventFinder",
  title: "EventFinder",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      step: 0,
      eventDate: '',
      eventLabels: [],
      eventCategories: [],
      eventItems: [],
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    setLabels(){
      var els = document.getElementsByClassName("active");
      for(var i = 0; i < els.length; i++)
      {
        this.eventLabels.push("'"+els[i].getAttribute("data-value")+"'");
      }
    },
    setCategories(){
      var els = document.getElementsByClassName("active");
      for(var i = 0; i < els.length; i++)
      {
        this.eventCategories.push(els[i].getAttribute("data-value"));
      }
    },
    loadResults() {
      const _this = this
      const fD = new FormData()
      fD.append('eventDate', this.eventDate)
      fD.append('eventLabels', this.eventLabels)
      fD.append('eventCategories', this.eventCategories)
      console.log(this.eventLabels)
      this.axios.post('eventfinder/getPartyFinderEvents', fD)
          .then(response => {
              if(response.status === 250) {
                console.log(response)
                _this.eventItems = response.data
              }
            })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>
/* Default */
#eventFinder h1{
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 900;
}
#eventFinder h2{
  text-align: center;
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.6);
}
#eventFinder .description h4{
  margin-bottom: 8px;
  font-weight: 200;
}
#eventFinder .description{
  margin-top: 16px;
  padding: 32px;
  text-align: center;
}
#eventFinder .description h3{
  color: #F2A43A;
  font-weight: 600;
  margin-bottom: 4px;
}
#eventFinder .description p{
  color: #ffffff;
  font-weight: 200;
  line-height: 20px;
}
#eventFinder .eventFinderForm{
  padding: 0 32px;
}
#eventFinder .eventFinderForm label{
  display: block;
  font-weight: 600;
  color: #F2A43A;
}
#eventFinder .eventFinderForm input{
  background-color: var(--second-bg-color);
  border: none;
  color: rgba(255,255,255,0.8);
  padding: 8px;
  border-radius: 10px;
  display: block;
  width: calc(100% - 16px);
}
#eventFinder .eventFinderForm button.submit{
  margin-top: 32px;
  padding: 12px 48px;
}
#eventFinder .selectionItem{
  cursor: pointer;
  padding: 16px;
  text-align: center;
  border: 2px solid var(--second-bg-color);
  position: relative;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
#eventFinder .selectionItem.active{
  border: 2px solid #ffffff;
}
#eventFinder .eventFinderForm .row-sp-12{
  position: relative;
}
#eventFinder .eventFinderForm .clickCover{
  position: absolute;
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  z-index: 2;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 10px;
}
#eventFinder .eventFinderForm .clickCover.active{
  border: 2px solid #ffffff;
}
#eventFinder .clickCover .activeCheck{
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 90%;
  background-color: #F2A43A;
  visibility: hidden;
  text-align: center;
  vertical-align: middle;
  right: -12px;
  bottom: -12px;
}
#eventFinder .clickCover .activeCheck img{
  padding-top: 4px;
}
#eventFinder .clickCover.active .activeCheck{
  visibility: visible;
}
#eventFinder .selectionItem span{
  font-size: 1.8em;
}
#eventFinder .selectionItem h5{
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 8px;
}

</style>
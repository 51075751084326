<template>
  <main class="content-500" id="tickets">
    <section class="t-center">
      <h1>Meine Tickets</h1>
      <h2>Übersicht der Tickets</h2>
    </section>
    <section class="pad-16" id="newTickets">
      <newTickets v-for="ntitems in newTickets" :new-tickets="ntitems" :key="ntitems.id" />
      <p class="t-center color-white" v-if="newTickets == ''">
        Du hast aktuell keine Tickets
      </p>
    </section>
    <section class="pad-16" id="oldTickets" v-if="oldTickets != ''">
      <div class="t-center">
        <h3>Alte Tickets</h3>
      </div>
      <oldTickets v-for="otitems in oldTickets" :old-tickets="otitems" :key="otitems.id" />
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import newTickets from "@/components/tickets/newTickets";
import oldTickets from "@/components/tickets/oldTickets";

export default {
  name: "Tickets",
  title: "Tickets",
  components: {
    'newTickets': newTickets,
    'oldTickets': oldTickets,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      newTickets: [],
      oldTickets: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.getnewTickets()
    this.getoldTickets()
  },
  methods: {
    getnewTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('tickets/getNewTickets', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.newTickets = response.data
            }
          })
    },
    getoldTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('tickets/getOldTickets', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.oldTickets = response.data
            }
          })
    },
  }
}
</script>

<style>
main#tickets{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#tickets h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#tickets h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: -16px;
}
main#tickets h3{
  font-size: 1.2em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: -16px;
}
main #newTickets{
  margin-top: 32px;
  padding: 32px 16px;
}
main #oldTickets h3{
  margin-bottom: 32px;
}
</style>
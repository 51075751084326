<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-6 row-sp-12 pad-8" style="padding-top: 10px!important;">
        <img v-if="menuModal == false" @click="menuModal = true" src="assets/images/icons/menu-icon.svg" class="width-32 menubtn">
        <img v-else @click="menuModal = false" src="assets/images/icons/close-icon.svg" class="width-28 menubtn">
      </div>
      <div class="col-sp-6 row-sp-12 pad-8 t-right">
        <router-link id="notificationBell" to="/notifications" @click="readNotifications()">
          <img src="assets/images/icons/bell-white.svg" class="width-24" style="vertical-align: top; margin: 10px 16px 0 0;">
          <span v-if="notificationCount > 0">{{ notificationCount }}</span>
        </router-link>
        <router-link v-if="!auth.isAuth" to="/login" @click="menuModal = false">
          <img src="assets/images/users/default.svg" class="image width-40 circular">
        </router-link>
        <router-link v-else to="/profil" @click="menuModal = false">
          <img :src="config.projectURL + 'assets/images/users/' + user.avatar" class="image width-40 circular">
        </router-link>
      </div>
    </div>
  </header>
  <transition name="modal" id="MenuModal" style="position: fixed;">
    <div class="vueModal" v-if="menuModal">
      <div class="vueModalOuter">
        <div class="menuContent">
          <ul id="navTop">
            <li>
              <router-link to="/home" @click="menuModal = false">
                Start
              </router-link>
            </li>
            <li>
              <router-link to="/profil" @click="menuModal = false">
                Mein Profil
              </router-link>
            </li>
            <li>
              <router-link to="/eventFinder" @click="menuModal = false">
                Wo gehts ab?
              </router-link>
            </li>
            <li>
              <router-link to="/tickets" @click="menuModal = false">
                Meine Tickets
              </router-link>
            </li>
            <li>
              <router-link to="/wallet" @click="menuModal = false">
                Guthaben <button class="button tiny bgcolor-orange color-white">
                  {{ (user.credits / 100).toFixed(2) }}€
                </button>
              </router-link>
            </li>
          </ul>
          <div class="locations" v-if="!auth.isAuth">
            <h4>Beliebte Standorte:</h4>
            <ul>
              <li>
                <router-link to="/city/79" @click="menuModal = false">
                  Hamburg
                </router-link>
              </li>
              <li>
                <router-link to="/city/14447" @click="menuModal = false">
                  Berlin
                </router-link>
              </li>
              <li>
                <router-link to="/city/88" @click="menuModal = false">
                  Köln
                </router-link>
              </li>
            </ul>
          </div>
          <div class="locations" v-else>
            <h4>Meine Standorte:</h4>
            <ul>
              <navCityItems v-for="ncitems in navCityItems" :nav-city-items="ncitems" :key="ncitems.id" />
            </ul>
          </div>
          <div class="footer">
            <router-link to="/settings" @click="menuModal = false">
              Einstellungen
            </router-link>
            <a href="https://20north.de/legal-notice" target="_blank">Impressum</a>
            <a href="https://20north.de/privacy-policy" target="_blank">Datenschutz</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import navCityItems from "@/components/navBar/myCities";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      menuModal: false,
      notificationCount: 0,
      navCityItems: navCityItems,
    }
  },
  mounted() {
    this.getNotificationsCount()
    this.getMyCities()
  },
  methods:{
    getNotificationsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotificationsCount', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = response.data.length
            }
          })
    },
    readNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = 0
            }
          })
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 6)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.navCityItems = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 16px;
}
header img.image{
  border: 2px solid #ffffff;
}
header .menubtn{
  background-color: var(--second-bg-color);
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}
header .menubtn img{
  filter: invert(1);
}
#MenuModal .menuContent{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding-top: 160px;
}
#MenuModal .menuContent #navTop{
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255,255,255,0.6);
}
#MenuModal .menuContent #navTop li{
  list-style: none;
  display: block;
  margin-bottom: 16px;
}
#MenuModal .menuContent #navTop li a{
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.6em;
  transition: 0.3s;
}
#MenuModal .menuContent #navTop li a button{
  float: right;
  margin-top: -4px;
}
#MenuModal .menuContent #navTop li a:hover{
  color: #F2A43A;
}
#MenuModal .menuContent .locations h4{
  margin-top: 16px;
  font-size: 1.5em;
  color: #F2A43A;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
#MenuModal .menuContent .locations{
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255,255,255,0.6);
  padding-bottom: 16px;
}
#MenuModal .menuContent .locations li{
  list-style: none;
  display: inline-block;
  margin-bottom: 8px;
  width: 50%;
}
#MenuModal .menuContent .locations li a{
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1em;
  transition: 0.3s;
}
#MenuModal .menuContent .locations li a:hover{
  color: #F2A43A;
}
#MenuModal .menuContent .footer{
  padding-top: 16px;
}
#MenuModal .menuContent .footer a{
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  color: #ffffff;
  font-size: 0.9em;
  margin-bottom: 8px;
  transition: 0.3s;
}
#MenuModal .menuContent .footer a:hover{
  color: #F2A43A;
}
#notificationBell {
  position: relative;
}
#notificationBell span{
  position: absolute;
  background-color: #F2A43A;
  color: #ffffff;
  z-index: 3;
  left: 8px;
  top: -16px;
  border-radius: 90%;
  width: 12px;
  height: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  padding: 4px;

}
</style>
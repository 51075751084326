<template>
  <main v-if="page == 'start'" class="content-500">
    <section id="headerTitle">
      <h1>Events</h1>
      <h2>in deiner Umgebung</h2>
    </section>
    <section id="headerSearch">
      <form>
        <div class="field">
          <form>
            <SimpleTypeahead id="city_search" placeholder="Suche nach Orten..." :items="cities" :min-input-length="1" :item-projection="item => {return item.name}" @selectItem="selectCity" @onInput="loadCitys" />
            <button @click="locatorButtonPressed" type="button" class="button bgcolor-orange color-white" style="padding: 2px 8px;">
              <img src="/assets/images/icons/location-white.svg" class="width-32">
            </button>
          </form>
        </div>
      </form>
    </section>
    <section v-if="cityName == null && cityItems != ''" id="locationscontainer" class="pad-16">
      <div class="pad-16 br-10">
        <div class="grid-12 header">
          <div class="col-sp-12 row-sp-12">
            <h4 class="color-white">
              Deine <span class="color-orange f-bold">Standorte</span>
            </h4>
          </div>
        </div>
        <div class="grid-12">
          <cityItems v-for="citems in cityItems" :city-items="citems" :key="citems.id" />
        </div>
      </div>
    </section>
    <section v-if="cityName != null" id="searchResultsHeader" class="pad-16">
      <div class="grid-12">
        <div class="col-sp-8 row-sp-12">
          <h2 style="font-size: 1.2em; padding-top: 8px;" class="f-normal">
            Events in <span class="f-bold color-orange">{{ cityName }}</span>
          </h2>
        </div>
        <div class="col-sp-4 row-sp-12 t-right">
          <button class="button color-white" style="padding: 3px 8px;" @click="filterModal = true;">
            <img src="/assets/images/icons/filter-white.svg" class="width-24">
          </button>
        </div>
      </div>
      <div id="activeFilter">
        <h4>Filter:</h4>
        <span class="label bgcolor-second">🏳️‍🌈 LGBTIQ+</span>
        <span class="label bgcolor-second">🎵 Techno</span>
      </div>
    </section>
    <section id="searchResults">
      <eventItems v-for="eitems in eventItems" :event-items="eitems" :key="eitems.id" />
      <p v-if="cityName != null && eventItems == ''" class="t-center">
        Leider konnten wir keine passenden Events in  <span class="color-orange">{{ cityName }}</span> finden
      </p>
    </section>
  </main>
  <transition name="modal" id="filterModal">
    <div class="vueModal" v-if="filterModal">
      <div class="vueModalOuter" @click="filterModal = false;" />
      <div class="vueModalInner bottom bgcolor-second">
        <div class="t-center pad-8">
          <h2 class="color-orange f-bold">
            Eventsfilter
          </h2>
          <div>
            <filterItems v-for="fitems in filterItems" :filter-items="fitems" :key="fitems.id" />
          </div>
          <div class="pad-16" />
          <h2 class="color-orange f-bold">
            Datum
          </h2>
          <div class="field t-center">
            <input type="date" class="pad-12 bgcolor-black color-white br-10" style="border: none; width: 100%; max-width: 200px;">
          </div>
          <div class="pad-16" />
          <button @click="filterModal = false;" class="button bgcolor-orange color-white">
            Events Filtern
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import eventItems from "@/components/home/event";
import filterItems from "@/components/home/filter";
import cityItems from "@/components/home/myCities";


import SimpleTypeahead from 'vue3-simple-typeahead'
export default {
  name: "Home",
  title: "Home",
  components: {
    'eventItems': eventItems,
    'filterItems': filterItems,
    'cityItems': cityItems,
    SimpleTypeahead
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      city: null,
      cityName: null,
      eventItems: '',
      page: 'start',
      cities: [],
      searchString: '',
      filterModal: false,
      filterItems: '',
      cityItems: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.geteventItems()
    this.getFilterItems()
    this.getMyCities()
    console.log(this.city)
  },
  methods: {
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
          position => {
            this.city = position.coords.latitude+', '+position.coords.longitude
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);
          },
          error => {
            console.log(error.message);
          },
      )
    },
    geteventItems(){
      const _this = this
      if(this.$route.params.id == null){
        this.axios.post('home/geteventItems')
            .then((response) => {
              if(response.status === 250){
                _this.eventItems = response.data
              }
            })
      }else{
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        fD.append('cityId', this.$route.params.id)
        this.axios.post('home/getEventsByCity', fD)
            .then((response) => {
              if(response.status === 250){
                _this.eventItems = response.data
              }
            })
        this.axios.post('home/getCityById', fD)
            .then((response) => {
              if(response.status === 250){
                _this.cityName = response.data.name
              }
            })
      }
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 3)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.cityItems = response.data
            }
          })
    },
    getFilterItems(){
      const _this = this
      this.axios.post('home/getFilterItems')
          .then((response) => {
            if(response.status === 250){
              _this.filterItems = response.data
            }
          })
    },
    loadCitys(event){
      const _this = this
      this.searchString = event.input
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('search', event.input)
      this.axios.post('home/cities', fD)
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectCity(item){
      this.city = item
      this.cityName = item.name
      console.log(this.cityName)
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('cityId', item.id)
      this.axios.post('home/getEventyByCity', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    },
    selectCityByLocations(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('cityId', id)
      this.axios.post('home/getEventyByCity', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    }
  }
}
</script>

<style>
main{
  padding-top: 80px;
}
section#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
}
section#headerTitle h2{
  font-family: 'Helvetica';
  font-weight: 100;
  text-align: center;
  font-size: 1em;
}
section#headerSearch{
  padding: 16px;
  clear: both;
}
section#headerSearch #city_search_wrapper{
  display: inline;
}
section#headerSearch #city_search{
  display: inline;
  width: calc(100% - 100px);
  border-radius: 8px;
  padding: 12px;
  border: none;
  background-color: var(--second-bg-color);
  color: #ffffff;
  padding-left: 36px;
  background-image: url('/public/assets/images/icons/search-grey.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 8px;
}
.simple-typeahead-list{
  position: absolute;
  width: calc(100% - 80px);
  z-index: 19;
  cursor: pointer;
  background-color: var(--second-bg-color);
}
.simple-typeahead-list-item{
  padding: 12px 16px!important;
  transition: 0.3s;
}
.simple-typeahead-list-item:hover{
  background-color: #000000;
}
section#headerSearch button{
  float: right;
}
section#searchResultsHeader h2{
  font-family: 'Helvetica';
  font-weight: 100;
}
#activeFilter{
  padding-top: 16px;
}
#activeFilter h4{
  display: inline;
  margin-right: 4px;
  font-family: 'Helvetica';
  font-weight: 100;
  color: rgba(255,255,255,0.8);
  font-size: 0.9em;
}
#activeFilter span{
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.8em;
  font-family: 'Helvetica';
  margin-right: 4px;
}
section#searchResults{
  padding: 16px;
}
#fitlerModal{
  position: fixed;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
}
#filterModal h2{
  font-size: 1.4em;
  margin-bottom: 16px;
}
</style>
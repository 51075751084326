<template>
  <main className="content-500" id="notifications">
    <div class="title">
      <h1>Was Gibt's?</h1>
      <h2>Deine Benachrichtigungen</h2>
    </div>
    <section class="pad-16">
      <notificationItems v-for="nitems in notificationItems" :notification-items="nitems" :key="nitems.id" />
      <div v-if="notificationItems == ''" class="t-center">
        <p class="color-white">
          Keine Benachrichtigungen vorhanden.
        </p>
      </div>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notificationItems from "@/components/notifications/notification";


export default {
  name: "Notifications",
  title: "Notifications",
  components: {
    notificationItems: notificationItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      notificationItems: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.getNotifications()
  },
  methods: {
    getNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationItems = response.data
            }
          })
    },
  }
}
</script>

<style>
#notifications .title h1{
  margin-top: 16px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
#notifications .title h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  text-align: center;
  margin-bottom: 32px;
}

</style>
<template>
  <main class="content-500">
    <section id="eventDetailsHeader">
      <div class="t-right">
        <div class="label button bgcolor-orange color-white">
          {{ eventDetails.label }}
        </div>
      </div>
      <img class="titleImage" :src="config.projectURL + 'assets/images/events/'+eventDetails.id+'/title.jpg'">
      <h4>{{ eventDetails.title }}</h4>
      <div class="meta">
        <ul>
          <li>
            <img src="assets/images/icons/calendar-white.svg" class="width-20">
            {{ covertStartTime }}
          </li>
          <li>
            <img src="assets/images/icons/location-white.svg" class="width-24" style="vertical-align: -6px;">
            {{ eventDetails.location }}
          </li>
        </ul>
      </div>
    </section>
    <section id="eventDetailsContent">
      <div class="pad-16 color-white br-10">
        <h5 class="f-bold color-orange">
          {{ eventDetails.subTitle }}
        </h5>
        <p>{{ eventDetails.description }}</p>
      </div>
      <div class="pad-16 t-center" id="eventLabels">
        <eventLabels v-for="elitems in eventLabels" :event-labels="elitems" :key="elitems.id" />
      </div>
    </section>
    <section id="joinCTA">
      <div class="pad-16 t-center">
        <h5 class="color-white f-bold">
          Bereit für eine verrückte Nacht?
        </h5>
        <button v-if="!isAttendees" class="button bgcolor-orange color-white" @click="joinEvent()">
          Jetzt Teilnehmen
        </button>
        <button v-else class="button bgcolor-second color-white" @click="dismissEvent()">
          Absagen
        </button>
        <button class="button bgcolor-second color-white sharebutton">
          <img src="assets/images/icons/share-white.svg" class="width-20">
        </button>
      </div>
    </section>
    <section class="bgcolor-second" id="ticketSale" v-if="parseInt(eventDetails.ticketSale) === 1">
      <div class="pad-16">
        <h4 class="f-bold color-white t-center">
          Jetzt Tickets sichern!
        </h4>
        <div>
          <div class="grid-12" v-if="eventDetails.ticketSaleLink != null && eventDetails.ticketSaleLink !== 'null'">
            <div class="col-sp-6 row-sp-12">
              <p>Tickets über externen Anbieter kaufen:</p>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <a :href="eventDetails.ticketSaleLink" target="_blank" class="button bgcolor-orange color-white">Ticket Shop öffnen</a>
            </div>
          </div>
          <div v-else>
            <ticketItems v-for="titems in ticketItems" :ticket-items="titems" :key="titems.id" />
            <p v-if="!ticketItems" class="t-center">
              Aktuell sind keine Tickets verfügbar
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="eventHoster" class="bgcolor-second">
      <div class="pad-16 t-center" @click="$router.push({name: 'Organizer', params: { id: eventDetails.eventOwner }});">
        <h5 class="color-white">
          Veranstalter:
        </h5>
        <img src="assets/images/eventhoster/unlimeted-events.png" class="width-200">
      </div>
    </section>
    <section id="otherEvents">
      <div class="pad-16">
        <h4>
          Andere <span class="color-orange">Events</span><br>
          vom gleichem Veranstalter
        </h4>
      </div>
      <section id="searchResults">
        <otherEventsItems v-for="oeitems in otherEventsItems" :other-events-items="oeitems" :key="oeitems.id" />
        <p class="t-center color-white" v-if="otherEventsItems == ''">
          Aktuell gibt es keine weiteren geplanten Events von diesem Veranstalter.
        </p>
      </section>
    </section>
  </main>
  <transition name="modal" id="filterModal">
    <div class="vueModal" v-if="filterModal">
      <div class="vueModalOuter" @click="filterModal = false;" />
      <div class="vueModalInner bottom bgcolor-second">
        <div class="t-center pad-8">
          <h2>Events Filtern</h2>
          <button @click="filterModal = false;" class="button fluid bgcolor-orange color-white">
            Okay
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import otherEventsItems from "@/components/eventDetails/otherEvents";
import eventLabels from "@/components/eventDetails/label";
import ticketItems from "@/components/eventDetails/ticket";

export default {
  name: "EventDetails",
  title: "EventDetails",
  components: {
    'otherEventsItems': otherEventsItems,
    'eventLabels': eventLabels,
    'ticketItems': ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      otherEventsItems: '',
      eventDetails: {},
      eventLabels: '',
      covertStartTime: '',
      isAttendees: false,
      ticketItems: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.openDetails()
    this.getIsAttendees()
    this.getTicketItems()
  },
  methods: {
    getOtherEventsItems(eventOwner){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      fD.append('ownerId', eventOwner)
      this.axios.post('eventdetails/getOtherEventsItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.otherEventsItems = response.data
            }
          })
    },
    openDetails() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      this.axios.post('eventdetails/geteventById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventDetails = response.data
              this.covertTime(this.eventDetails.startTime)
              this.getOtherEventsItems(this.eventDetails.eventOwner)
              this.getEventLabels(this.eventDetails.categories)
            }
          })
    },
    getEventLabels(labelIds) {
      const _this = this
      const fD = new FormData()
      fD.append('labelIds', labelIds)
      this.axios.post('eventdetails/getEventLabels', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventLabels = response.data
            }
          })
    },
    getTicketItems() {
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      this.axios.post('eventdetails/getTicketItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.ticketItems = response.data
            }
          })
    },
    getIsAttendees() {
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      this.axios.post('eventdetails/getIsAttendees', fD)
          .then((response) => {
            if(response.status === 250){
              _this.isAttendees = true
            }else{
              _this.isAttendees = false
            }
          })
    },
    joinEvent() {
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      this.axios.post('eventdetails/joinEvent', fD)
          .then(() => {
            _this.getIsAttendees()
          })
    },
    dismissEvent() {
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      this.axios.post('eventdetails/dismissEvent', fD)
          .then(() => {
            _this.getIsAttendees()
          })
    },
    covertTime(timestamp){
      const _this = this
      let timestampArr = timestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  }
}
</script>

<style>
main{
  padding-top: 80px;
}
section#searchResults{
  padding: 16px;
  margin-top: 32px;
}
section#eventDetailsHeader{
  padding: 16px;
}
section#eventDetailsHeader .label{
  margin-right: 16px;
  margin-bottom: -32px;
  position: relative;
  z-index: 3;
  padding: 10px 8px;
  text-transform: none;
}
section#eventDetailsHeader img.titleImage{
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  margin-bottom: 16px;
  margin-top: -8px;
}
section#eventDetailsHeader h4{
  font-size: 1.6em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: rgba(255,255,255,1);
  margin-bottom: 0px;
}
section#eventDetailsHeader .meta{
  padding-top: 4px;
  opacity: 0.6;
}
section#eventDetailsHeader .meta ul{
  padding: 0;
  margin: 0;
}
section#eventDetailsHeader .meta ul li{
  padding: 0;
  margin: 0;
  display: inline;
  margin-right: 12px;
  font-weight: 200;
  font-size: 0.8em;
}
section#eventDetailsHeader .meta ul li img{
vertical-align: -4px;
}
section#eventDetailsContent{
  padding-top: 0;
  margin-top: -16px;
}
section#eventDetailsContent h5{
  font-size: 1.2em;
}
section#eventDetailsContent p{
  margin-top: 8px;
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: rgba(255,255,255,0.8);
}
section#eventDetailsContent #eventLabels span{
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.9em;
  font-family: 'Helvetica';
  margin-right: 8px;
  display: inline-block;
  margin-bottom: 8px;
}
section#joinCTA{
  margin-bottom: 32px;
}
section#joinCTA h5{
  margin-bottom: 16px;
  font-size: 1.1em;
  font-weight: 500;
}
section#joinCTA button:not(.sharebutton){
  text-transform: none;
  padding: 12px 32px;
  font-weight: 500;
  font-size: 0.8em;
  margin-right: 16px;
  vertical-align: top;

}
section#joinCTA button.sharebutton{
  padding: 8px 10px;
}
section#ticketSale h4{
  font-size: 1.3em;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}
section#ticketSale button{
  text-transform: none;
}
section#ticketSale p{
  color: rgba(255,255,255,0.6);
}
section#eventHoster h5{
  font-weight: 300;
  margin-top: 32px;
  font-size: 0.8em;
  margin-bottom: 8px;
}
section#otherEvents h4{
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 32px;
}
section#otherEvents h4 span{
  font-weight: 700;
}
</style>
<template>
  <footer>
    <ul>
      <li>
        <router-link to="/home">
          <img src="assets/images/icons/home-white.svg">
          <span>Home</span>
        </router-link>
      </li>
      <li>
        <router-link to="/eventFinder">
          <img src="assets/images/icons/search-white.svg">
          <span>Suche</span>
        </router-link>
      </li>
      <li class="middle">
        <router-link to="/create">
          <img src="assets/images/icons/plus-white.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/myEvents">
          <img src="assets/images/icons/calendar-white.svg">
          <span>Meine Events</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profil">
          <img src="assets/images/icons/user-white.svg">
          <span>Profil</span>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
  }
}
</script>

<style scoped>
footer{
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  min-height: 48px;
  padding: 8px 0 6px 0;
  background-color: var(--second-bg-color);
}
footer ul{
  padding: 0;
  margin: 0;
  width: 100%;
}
footer ul li{
  width: 20%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  text-align: center;
}
footer ul li a{
  display: block;
  color: rgba(255,255,255,0.8);
  text-align: center;
}
footer ul li a img{
  width: 24px;
  display: block;
  margin: 0 auto;
}
footer ul li a span{
  width: 24px;
  display: inline;
  font-size: 0.8em;
  margin: 0 auto;
}
footer ul li.middle{
  position: relative;
  margin-top: -40px;
}
footer ul li.middle img{
  background-color: #F2A43A;
  border-radius: 90%;
  width: 24px;
  height: 24px;
  margin-top: 0px;
  padding: 16px;
}
</style>
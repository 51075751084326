<template>
  <input type="file" ref="file" style="display: none;" @change="crop">
  <div @click="$refs.file.click()" v-if="!src && !init">
    <slot>
      <button class="button bgcolor-orange color-white tiny">
        Eigene Datei hochladen
      </button>
    </slot>
  </div>
  <div>
    <vue-cropper ref="cropper" :src="src" alt="Source Image" v-if="src" @cropend="getBlob" @ready="getBlob" />
  </div>
</template>

<script>
import {ref} from "vue";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "CropperUpload",
  components: { VueCropper},
  props: {
    ratio: {
      type: Number,
      default: 0
    },
    init: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    const file = ref(null)
    let src = ref(null)
    const crop = async() => {
      src.value = URL.createObjectURL(file.value.files[0])
    }
    return {crop,  file, src}
  },
  watch: {
    src(){
      if(this.src && this.ratio){
        this.$nextTick(() => {
          this.$refs.cropper.setAspectRatio(this.ratio)
        })
      }
    },
  },
  mounted() {
    if(this.init) this.$refs.file.click();
  },
  methods: {
    getBlob(){
      const _this = this
      const canvas = this.$refs.cropper.getCroppedCanvas()
      canvas.toBlob(blob => {
        _this.$emit("cropped", blob)
      })
    }
  }
}
</script>

<style scoped>

</style>
import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import profil from "@/views/profil";
import wallet from "@/views/wallet";
import create from "@/views/create";
import myEvents from "@/views/myEvents";
import settings from "@/views/settings";
import notifications from "@/views/notifications"
import eventFinder from "@/views/eventFinder";
import eventDetails from "@/views/eventDetails";
import organizer from "@/views/organizer";
import tickets from "@/views/tickets";
import login from "@/views/login";
import home from "@/views/home";
import register from "@/views/register";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";
const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    components: {
      navBar: navBar,
      default: home,
      footer: footer
    },
  },
  {
    path: '/city/:id',
    name: 'Suche',
    components: {
      navBar: navBar,
      default: home,
      footer: footer
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      navBar: navBar,
      default: login,
      footer: footer
    },
    meta: { noAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      navBar: navBar,
      default: register,
      footer: footer
    },
    meta: { noAuthnoAuth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profil,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    components: {
      navBar: navBar,
      default: wallet,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/create',
    name: 'Create',
    components: {
      navBar: navBar,
      default: create,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/myEvents',
    name: 'MyEvents',
    components: {
      navBar: navBar,
      default: myEvents,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/tickets',
    name: 'Tickets',
    components: {
      navBar: navBar,
      default: tickets,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    components: {
      navBar: navBar,
      default: notifications,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      navBar: navBar,
      default: settings,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/eventFinder',
    name: 'EventFinder',
    components: {
      navBar: navBar,
      default: eventFinder,
      footer: footer
    }
  },
  {
    path: '/organizer/:id',
    name: 'Organizer',
    components: {
      navBar: navBar,
      default: organizer,
      footer: footer
    }
  },
  {
    path: '/eventDetails/:id',
    name: 'EventDetails',
    components: {
      navBar: navBar,
      default: eventDetails,
      footer: footer
    }
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/home' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Home'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
          //BEHEBUNG!
          console.log(e)
        })
    config.loading = true
    next()
  }
})

export default router

<template>
  <main class="content-500">
    <section id="walletHeader">
      <div class="pad-16">
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h1>Dein Guthaben</h1>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <button class="button bgcolor-orange color-white">
              {{ (user.credits / 100).toFixed(2) }}€
            </button>
          </div>
        </div>
      </div>
    </section>
    <section id="walletCTA">
      <div class="pad-8">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8">
            <button @click="generatePayQRCode()" class="button bgcolor-orange color-white" id="pay">
              <img src="assets/images/icons/pay-white.svg" class="width-80">
              <h4>Bezahlen</h4>
            </button>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <button @click="chargeModal = true;" class="button bgcolor-second color-white" id="charge">
              <img src="assets/images/icons/charge-white.svg" class="width-80">
              <h4>Guthaben aufladen</h4>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section id="paymentHistory">
      <div class="pad-16">
        <h2>Deine letzten Transaktionen</h2>
        <div v-if="loadingTransaction">
          lädt...
        </div>
        <div class="pad-32 t-center" v-else-if="!transactions.length">
          <p>Keine Transaktionen vorhanden</p>
        </div>
        <div v-else>
          <div v-for="(transaction, index) in transactions" :key="index">
            <div class="paymentItem">
              <div class="paymentItemAmount">
                <div class="grid-12">
                  <div class="col-sp-8 row-sp-12 t-left" v-if="transaction.title == null">
                    <p v-if="transaction.amount >= 0">
                      Guthaben aufgeladen
                    </p>
                  </div>
                  <div class="col-sp-8 row-sp-12 t-left" v-else>
                    <p>{{ transaction.title }} - Getränke</p>
                  </div>
                  <div v-if="transaction.amount >= 0" class="col-sp-4 row-sp-12 t-right positive">
                    +{{ (transaction.amount / 100).toFixed(2) }}€
                  </div>
                  <div v-else class="col-sp-4 row-sp-12 t-right negative">
                    {{ (transaction.amount / 100).toFixed(2) }}€
                  </div>
                </div>
              </div>
              <div class="paymentItemDate">
                {{ transaction.date }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="modal" id="paymentModal">
      <div class="vueModal" v-if="paymentModal">
        <div class="vueModalOuter" @click="paymentModal = false;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>evently<span class="f-bold">Pay</span></h2>
            <h3 class="color-white">
              Zeige diesen Code zum bezahlen vor
            </h3>
            <div class="t-center" @click="generatePayQRCode">
              <img id="qrious">
            </div>
            <div>Aktuelles Guthaben: {{ (user.credits / 100).toFixed(2) }}€</div>
            <div class="pad-8" />
            <button @click="paymentModal = false;" class="button bgcolor-black color-white fluid">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" id="chargeModal">
      <div class="vueModal" v-if="chargeModal">
        <div class="vueModalOuter" @click="chargeModal = false;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>Guthaben aufladen</h2>
            <h3>Betrag auswählen</h3>
            <div class="field">
              <input id="chargeInput" type="number" step="0.01" v-model="chargeAmount"><span>€</span>
            </div>
            <div class="grid-12 t-center selectionButtons">
              <div class="col-sp-4 row-sp-12 pad-4">
                <button @click="chargeAmount = 10.00" class="button bgcolor-black color-white">
                  10,00€
                </button>
              </div>
              <div class="col-sp-4 row-sp-12 pad-4">
                <button @click="chargeAmount = 25.00" class="button bgcolor-black color-white">
                  25,00€
                </button>
              </div>
              <div class="col-sp-4 row-sp-12 pad-4">
                <button @click="chargeAmount = 50.00" class="button bgcolor-black color-white">
                  50,00€
                </button>
              </div>
            </div>
            <button class="button bgcolor-orange color-white fluid" @click="getLink">
              Weiter
            </button>
            <div class="pad-4" />
            <button @click="chargeModal = false;" class="button bgcolor-black color-white fluid">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Qrious from "qrious";

export default {
  name: "Wallet",
  title: "Wallet",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      paymentModal: false,
      chargeModal: false,
      chargeAmount: 5.00,
      loadingTransaction: true,
      transactions: [],
      paymentIntent: ''
    }
  },
  mounted() {
    const _this = this
    this.config.loading = false
    const fD = new FormData()
    fD.append('sessionID', this.auth.sessionID)
    this.axios.post('wallet/transactions', fD)
        .then(r => {
          if(r.status === 250) {
            _this.transactions = r.data
            _this.loadingTransaction = false
          }
        })
        .catch(e => {console.log(e)})
  },
  methods: {
    generatePayQRCode() {
      const _this = this
      this.paymentModal = true;
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('wallet/createPaymentIntent', fD)
          .then(r => {
            if(r.status === 250) {
              _this.paymentIntent = r.data
              new Qrious({
                element: document.getElementById('qrious'),
                size: 350,
                value: _this.paymentIntent,
                background: 'transparent',
                foreground: '#F2A43A',
                level: 'H',
              });
            }
          })
          .catch(e => {console.log(e)})
    },
    getLink(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('quantity', Math.round(this.chargeAmount * 100))
      this.axios.post('/wallet/getLink', fD)
          .then(r => {
            if(r.status === 250) location.href = r.data
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>
#walletHeader{
  padding-top: 16px;
}
#walletHeader h1{
  font-size: 2em;
  font-weight: 300;
}
#walletHeader button{
  font-size: 1em;
  padding: 8px;
}
#walletCTA button{
  width: 100%;
}
#paymentModal{
  position: fixed;
}
#paymentModal .vueModalInner{
  min-width: 80vw;
}
#paymentModal h2{
  font-size: 2em;
}
#paymentModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal{
  position: fixed;
}
#chargeModal .vueModalInner{
  min-width: 80vw;
}
#chargeModal h2{
  font-size: 1.8em;
  font-weight: 300;
}
#chargeModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal .selectionButtons{
  max-width: 250px;
  margin: 0 auto;
}
#chargeModal .selectionButtons button{
  padding: 8px;
  font-size: 1.0em;
  margin-bottom: 32px;
}
#chargeModal #chargeInput{
  padding: 16px;
  width: calc(100% - 20px);
  max-width: 200px;
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 1.2em;
}
#chargeModal .field span{
  font-size: 1.5em;
  margin-left: 8px;
}
#paymentHistory h2{
  font-size: 1.2em;
}
.paymentItem{
  margin-top: 8px;
  padding: 12px;
  border-radius: 10px;
  background-color: var(--second-bg-color);
}
.paymentItem .paymentItemAmount .t-right{
  font-size: 1.2em;
}
.paymentItem .paymentItemAmount .t-right.negative{
  color: darkred;
}
.paymentItem .paymentItemAmount .t-right.positive{
  color: green;
}
.paymentItem .paymentItemDate{
  color: rgba(255,255,255,0.6);
  font-size: 0.8em;
}
</style>
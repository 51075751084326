import { defineStore } from 'pinia'
import axios from 'axios'
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

const error = new ErrorEvent('doof')

export const authStore = defineStore('auth', {
    state: () => ({
        isAuth: false,
        nodeID: null,
        cookie:  localStorage.getItem('cookie'),
        sessionID: localStorage.getItem('sessionID'),
        lastActivity: 0
    }),
    actions: {
        /**
         * login via vue
         * @param email {string}
         * @param password {string}
         * @param cookie {bool}
         * @returns {Promise}
         */
        login(email, password, cookie) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)
                fD.append('password', password)
                fD.append('cookie', cookie)

                const config = configStore()
                const user = userStore()
                axios.post(config.projectURL+'login/login',fD)
                    .then((response) => {
                        const data = response.data
                        switch(response.status){
                            case 250:
                                user.id = data.user_id
                                user.email = data.email
                                user.firstname = data.firstname
                                user.lastname = data.lastname
                                user.avatar = data.avatar
                                user.credits = data.credits
                                config.darkMode = data.darkMode
                                _this.isAuth = true
                                _this.sessionID = data.sessionID
                                if(cookie) {
                                    _this.cookie = data.cookie
                                    localStorage.setItem('cookie', _this.cookie)
                                }
                                localStorage.setItem('sessionID',_this.sessionID)

                                resolve(250)
                                break;

                            case 251:
                                _this.isAuth = false
                                reject(error);
                                break;

                            default:
                                _this.isAuth = false
                                reject(error);
                                break;
                        }
                    })
                    .catch((e)=> {
                        reject(4)
                    })
            })
        },
        /**
         * reset Password
         * @param email
         * @returns {Promise<unknown>}
         */
        resetPassword(email) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)

                const config = configStore()
                const user = userStore()
                axios.post(config.projectURL+'login/reset',fD)
                    .then((response) => {
                        resolve(response.status)
                        console.log(response)
                    })
                    .catch(()=> reject(error))
            })
        },
        /**
         * use key to reset password
         * @param key
         * @returns {Promise<unknown>}
         */
        restorePassword(token, password) {
            const _this = this
            return new Promise((resolve, reject) => {
                const config = configStore()
                const fD = new FormData()
                fD.append('token', token)
                fD.append('password', password)
                axios.post(config.projectURL+'login/restore',fD)
                    .then((response) => {
                        resolve(response.status)
                    })
                    .catch(()=> reject(error))
            })
        },
        /**
         * register
         * @param email
         * @param firstname
         * @param lastname
         * @param password
         * @param avatar
         */
        register(email, firstname,lastname, password, avatar) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)
                fD.append('password', password)
                fD.append('firstname', firstname)
                fD.append('lastname', lastname)
                if(avatar) fD.append('avatar', avatar, 'avatar')

                const config = configStore()
                const user = userStore()
                axios.post(config.projectURL+'login/register',fD, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        console.log(response.data)
                        const data = response.data
                        switch(response.status){
                            case 250:
                                user.id = data.user_id
                                user.email = data.email
                                user.firstname = data.firstname
                                user.lastname = data.lastname
                                user.avatar = data.avatar
                                user.credits = data.credits
                                config.darkMode = data.darkMode
                                _this.isAuth = true
                                _this.sessionID = data.sessionID
                                localStorage.setItem('sessionID',_this.sessionID)
                                _this.lastActivity = Date.now()
                                resolve(250)
                                break;

                            default:
                                _this.isAuth = false
                                reject(error);
                                break;
                        }
                    })
                    .catch((e)=> {
                        reject(4)
                    })
            })
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        logout() {
            const _this = this
            const user = userStore()
            const config = configStore()

            axios.post(config.projectURL + 'login/logout')
                .then(function (response) {
                    console.log(response)
                    if (response.status === 250) {
                        localStorage.clear()
                        sessionStorage.clear()
                        localStorage.setItem('darkMode', JSON.stringify(config.darkMode))

                        user.$reset()
                        _this.$reset()

                        const cookies = document.cookie.split(';')

                        for (var i = 0; i < cookies.length; i++) {
                            const cookie = cookies[i]
                            const eqPos = cookie.indexOf('=')
                            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
                        }
                        _this.$router.push('/')
                    }
                })
                .catch((e) => {
                    console.error('doof', e)
                })
        },
        /**
         * Updates Status, checks Login
         * @returns {Promise}
         */
        update() {
            const _this = this
            const user = userStore()
            const config = configStore()
            return new Promise(function (resolve, reject) {
                if (_this.isAuth && (_this.lastActivity + 60000) > Date.now()) {
                    resolve()
                }
                else if (_this.sessionID != null) {
                    _this.lastActivity = Date.now()
                    console.log('update')
                    const fD = new FormData()
                    //BEHEBUNG
                    fD.append('sessionID', _this.sessionID)
                    axios.post(config.projectURL + 'login/update', fD)
                        .then((response) => {
                            console.log(response)
                            if (response.status === 250){
                                const data = response.data
                                user.id = data.user_id
                                user.email = data.email
                                user.firstname = data.firstname
                                user.lastname = data.lastname
                                user.avatar = data.avatar
                                user.credits = data.credits
                                config.darkMode = data.darkMode
                                if(config.cookie){
                                    _this.cookie = data.cookie
                                    localStorage.setItem('cookie', _this.cookie)
                                }
                                localStorage.setItem('sessionID',_this.sessionID)
                                _this.sessionID = data.sessionID
                                _this.isAuth = true
                                resolve(response.status)
                            }else{
                                _this.sessionID = null
                                localStorage.removeItem('sessionID')
                                _this.cookieLogin()
                                    .then(() => {
                                        _this.isAuth = true
                                        resolve()
                                    })
                                    .catch((e) => {
                                        _this.isAuth = false
                                        reject(error)
                                    })
                            }
                        })
                        .catch((e) => {
                            reject(error)
                        })
                }
                else if(_this.cookie != null){
                    _this.cookieLogin()
                        .then(() => {
                            _this.isAuth = true
                            resolve()
                        })
                        .catch((e) => {
                            _this.isAuth = false
                            reject(error)
                        })
                }else{
                    reject(error)
                }
            })
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        cookieLogin () {
            const _this = this
            return new Promise(function (resolve, reject) {
                const config = configStore()
                const user = userStore()
                try {
                    if (_this.cookie != null) {
                        const fD = new FormData()
                        fD.append('cookie', _this.cookie)

                        axios.post(config.projectURL + 'login/cookie', fD)
                            .then((response) => {
                                if (response.status == 250) {
                                    const data = response.data
                                    user.id = data.user_id
                                    user.email = data.email
                                    user.firstname = data.firstname
                                    user.lastname = data.lastname
                                    user.avatar = data.avatar
                                    user.credits = data.credits
                                    config.darkMode = data.darkMode
                                    _this.cookie = data.cookie
                                    _this.sessionID = data.sessionID
                                    _this.isAuth = true
                                    localStorage.setItem('cookie', _this.cookie)
                                    localStorage.setItem('sessionID',_this.sessionID)
                                    resolve(1);
                                } else {
                                    _this.$reset()
                                    user.$reset()
                                    localStorage.removeItem('sessionID')
                                    localStorage.removeItem('cookie')
                                    reject(error)
                                }
                            })
                            .catch(() => {
                                reject(error)
                            })
                    }else{
                        reject(error)
                    }
                } catch (e) {
                    reject(error)
                }
            })
        }
    }
})
<template>
  <li>
    <router-link to="/home" @click="menuModal = false; $router.push({name: 'Suche', params: { id: navCityItems.id }});">
      {{ navCityItems.name }}
    </router-link>
  </li>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NavCityItems",
  props: ["navCityItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
li{
  list-style: none;
  display: inline-block;
  margin-bottom: 8px;
  width: 50%;
}
li a{
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1em;
  transition: 0.3s;
}
li a:hover{
  color: #F2A43A;
}
</style>